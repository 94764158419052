import { useEffect } from "react";
import { useAtom } from "jotai";
import { confirmModalAtom } from "jotai/modal/confirmModalAtom";

export default function useConfirm() {
	const [{ content }, handleModal] = useAtom(confirmModalAtom);

	const handleClose = () => {
		handleModal({
			content: "",
			leftBtn: {
				disabled: false,
				label: "",
				event: () => {},
			},
			rightBtn: {
				disabled: false,
				label: "",
				event: () => {},
			},
		});
	};

	const handeLeftButtonEvent = (event?: () => void) => () => {
		event?.();
		handleClose();
	};

	const handeRightButtonEvent = (event: () => void) => () => {
		event?.();
		handleClose();
	};

	const setConfirmModal = ({
		content: text,
		leftBtn,
		rightBtn,
	}: {
		content: string;
		leftBtn?: { disabled?: boolean; label?: string; event?: () => void };
		rightBtn: { disabled?: boolean; label: string; event: () => void };
	}) => {
		handleModal({
			content: text,
			leftBtn: {
				...leftBtn,
				event: handeLeftButtonEvent(leftBtn?.event),
			},
			rightBtn: {
				...rightBtn,
				event: handeRightButtonEvent(rightBtn.event),
			},
		});
	};

	useEffect(() => {
		if (!content) return;
		const block = () => window.history.pushState(null, "", window.location.href);

		window.addEventListener("popstate", block);
		return () => window.removeEventListener("popstate", block);
	}, []);

	return { setConfirmModal, handleClose };
}
