import { toast } from "react-toastify";

export const showToastMessage = (content: string | (() => JSX.Element)) => {
	toast(content, {
		position: "bottom-center",
		autoClose: 5000,
		hideProgressBar: true,
		closeOnClick: true,
		closeButton: false,
		pauseOnHover: true,
		draggable: true,
		progress: undefined,
		theme: "dark",
	});
};
