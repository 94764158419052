/** @jsxImportSource @emotion/react */
import { useEffect } from "react";
import { useHotkeysContext } from "react-hotkeys-hook";
import { useAtom } from "jotai";
import Modal from "components/layout/Modal";
import Button from "components/atoms/Button";
import { COLOR } from "styles/common";
import { stylingText } from "libs/styling";
import { jsxModalAtom } from "jotai/modal/jsxModalAtom";

export default function JsxModal() {
	const { enableScope, disableScope } = useHotkeysContext();
	const [jsxContent, setJsxContent] = useAtom(jsxModalAtom);

	const handleClose = () => {
		setJsxContent(null);
		disableScope("alertModal");
		enableScope("tagging");
		enableScope("image");
	};

	useEffect(() => {
		if (jsxContent) {
			disableScope("tagging");
			disableScope("image");
			enableScope("alertModal");
		}
	}, [jsxContent]);

	return (
		<Modal open={!!jsxContent}>
			<section css={wrapper}>
				<div css={topWrapper}>
					<p css={styledTitle}>알림</p>
					<div css={styledContent}>
						<span>{jsxContent}</span>
					</div>
				</div>
				<Button.Button css={button} customStyle="filled" onClick={handleClose}>
					확인
				</Button.Button>
			</section>
		</Modal>
	);
}

const wrapper = {
	width: "406px",
	maxHeight: "232px",
	padding: "24px",
	textAlign: "center" as const,
	backgroundColor: COLOR.white,
	borderRadius: "5px",
	boxShadow: "0px 2px 6px 2px #00000026",
};

const topWrapper = {
	display: "flex",
	flexDirection: "column" as const,
	gap: "24px",
	marginBottom: "40px",
};

const styledTitle = {
	...stylingText({ size: "20", weight: "medium" }),
};

const styledContent = {
	whiteSpace: "pre-wrap" as const,
	wordBreak: "keep-all" as const,
	...stylingText({ size: "14", weight: "medium" }),
};

const button = {
	width: "160px",
};
