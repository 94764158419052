import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./styles/fonts/index.css";
import "./styles/reset.css";
import "./styles/common.css";

const container = document.getElementById("root");

ReactDOM.createRoot(container).render(
	// <React.StrictMode>
	<App />,
	// </React.StrictMode>,
);
